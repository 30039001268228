import "core-js/stable";

import { setWindowProperties } from "./js/service/window";
import { PlayerSdk, PlayerSdkRender } from "./js/types/common";

const appPromise = import(
  /* webpackPreload: true */
  "./app"
);

const windowRender: PlayerSdkRender = async ({
  element,
  onPlayerReady,
  props
}) => {
  const application = await appPromise;

  element.className += " yle_areena_player_dynamic";

  return application.renderPlayer({
    element,
    onPlayerReady,
    props
  });
};

const sdk: PlayerSdk = { render: windowRender };

setWindowProperties(sdk);

appPromise.then(app => app.embedPlayerElements());
