import { useEffect, useRef } from "react";

export const isAreenaEmbed = () =>
  [__AREENA_URL, __ARENAN_URL].includes(window.location.origin);

export const isYleEmbed = (): boolean =>
  window.location.hostname.includes("yle.fi");

export const isYleAppEmbed = (): boolean =>
  window.location.hostname.includes("app.yle.fi");

export const truncate = (str = "", length: number): string => {
  if (str.length <= length) {
    return str;
  } else {
    const shortened = str.substring(0, length);
    return shortened.substring(0, shortened.lastIndexOf(" "));
  }
};

export const readCookie = (name: string): string => {
  const match = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
  return match ? match[2] || "" : "";
};

export const getNested = (path, object) =>
  path.reduce((xs, x) => (xs && xs[x] ? xs[x] : undefined), object);

export const usePrevious = <T>(value: T | undefined): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const upperCaseFirstLetter = (str?: string): string | undefined =>
  str && str.charAt(0).toLocaleUpperCase() + str.slice(1);

/**
 * Allow aspect ratios in the format "x:y" or "auto"
 * x and y must be integers greater than 0
 */
export const isValidAspectRatio = (aspectRatio: string): boolean => {
  return (
    /^[1-9][0-9]*:[1-9][0-9]*$/g.test(aspectRatio) || aspectRatio === "auto"
  );
};
