import * as t from "io-ts";
import { Type } from "io-ts";

import { isValidAspectRatio } from "./helpers/commonUtils";
import {
  DeprecatedPlayerEmbedParams,
  Language,
  PlayerEmbedParams,
  PlayerEvent,
  SubtitlesBackground,
  SubtitlesDefault,
  Theme
} from "./types/common";
import * as Enums from "./enums";
import { State } from "./enums";

const aspectRatioType = new t.Type<string, string, unknown>(
  "aspectRatio",
  (input: unknown): input is string => {
    return typeof input === "string";
  },
  (input, context) =>
    typeof input === "string" && isValidAspectRatio(input)
      ? t.success(input)
      : t.failure(input, context),
  t.identity
);

const numberNotNaNType = new t.Type<number, number, unknown>(
  "numberNotNaN",
  (input: unknown): input is number => {
    return typeof input === "number";
  },
  (input, context) =>
    !isNaN(Number(input))
      ? t.success(Number(input))
      : t.failure(input, context),
  t.identity
);

export const REGIONS = {
  FINLAND: "Finland",
  WORLD: "World"
};

export const COUNTRY_CODE = {
  FI: "FI"
};

export const LANGUAGE_CODE = {
  ENG: "eng",
  FIN: "fin",
  SME: "sme",
  SMN: "smn",
  SMS: "sms",
  SWE: "swe"
} as const;

const playerEventOptionsObject = {
  afterSeek: null,
  backButtonClicked: null,
  chatButtonClicked: null,
  error: null,
  mediaChanged: null,
  onFinish: null,
  onProgress: null,
  onTimeUpdate: null,
  pause: null,
  play: null,
  playing: null,
  ratechange: null,
  volumeChange: null
} satisfies Record<PlayerEvent, null>;

export const playerEventOptions = Object.keys(playerEventOptionsObject);

export const JS = {
  IMAGE_CLASS: "JS-player-preview-image"
};

export const DEFAULT_ASPECT_RATIO = {
  LANDSCAPE: "16:9",
  PORTRAIT: "9:16"
};

export const SHOW_TIMER_BEFORE_START_IN_SECONDS = 60 * 60;
export const RETRY_TIME_BEFORE_TIMER_FAILS_IN_SECONDS = 5 * 60;

export const MIMETYPES = {
  HLS: "application/x-mpegurl",
  MP3: "audio/mp3"
};

export const WINDOW_GLOBAL = "ylePlayer";
export const START_FROM = "ylePlayer.startFrom";

export const UI_SIZE_XSMALL_BREAKPOINT = 470;

export const UI_SIZE_SMALL_BREAKPOINT = 768;

export const UI_SIZE_LARGE_BREAKPOINT = 1024;

export const HLS_CONFIG_PARAMS = {
  DEFAULT_START_LEVEL: -1,
  MAX_MAX_BUFFER_LENGTH: 600,
  MAX_MAX_BUFFER_LENGTH_PRELOAD: 10,
  PREFERRED_START_LEVEL_QUALITY: 720
};

export const SUBTITLES_BACKGROUND_OPTIONS = [
  Enums.SUBTITLES_BACKGROUND.BLACK,
  Enums.SUBTITLES_BACKGROUND.WHITE,
  Enums.SUBTITLES_BACKGROUND.NONE
] as const;

const SubtitlesBackgroundType = t.keyof({
  black: null,
  none: null,
  white: null
}) satisfies Type<SubtitlesBackground>;

const LanguageType = t.keyof({
  eng: null,
  fin: null,
  sme: null,
  smn: null,
  sms: null,
  swe: null
}) satisfies Type<Language>;

const SubtitlesDefaultType = t.keyof({
  hardOfHearing: null,
  off: null,
  translation: null
}) satisfies Type<SubtitlesDefault>;

const ThemeType = t.keyof({
  auto: null,
  dark: null,
  light: null
}) satisfies Type<Theme>;

export const EmbedOptions = t.intersection([
  t.type({
    autoControls: t.boolean,
    autoMute: t.boolean,
    autoplay: t.boolean,
    backButton: t.boolean,
    chromecast: t.boolean,
    description: t.boolean,
    language: LanguageType,
    logo: t.boolean,
    loop: t.boolean,
    pictureInPicture: t.boolean,
    playbackHistory: t.boolean,
    playerUi: t.boolean,
    recommendations: t.boolean,
    sharing: t.boolean,
    subtitles: t.boolean,
    // Theme means the type of background where the audio player is embedded.
    theme: ThemeType,
    title: t.boolean
  }),
  t.partial({
    analytics: t.boolean,
    analyticsContext: t.UnknownRecord,
    aspectRatio: aspectRatioType,
    autoFocus: t.boolean,
    autoPlayNextEpisode: t.boolean,
    backgroundVideo: t.boolean,
    chatButton: t.boolean,
    controls: t.boolean,
    id: t.string,
    optimizeStartLevel: t.boolean,
    playbackRate: numberNotNaNType,
    playButton: t.boolean,
    playFullScreen: t.boolean,
    preload: t.string,
    previewImage: t.boolean,
    replayButton: t.boolean,
    seek: numberNotNaNType,
    smallPlayButton: t.boolean,
    spinner: t.boolean,
    src: t.string,
    srcDuration: numberNotNaNType,
    srcTitle: t.string,
    subtitlesBackground: SubtitlesBackgroundType,
    subtitlesDefault: SubtitlesDefaultType,
    timeLeft: t.boolean,
    unmuteButton: t.boolean,
    yleAudioPlayer: t.boolean,
    ylePlayer: t.boolean
  })
]) satisfies Type<PlayerEmbedParams>;

export const PLAYER_DEFAULT_VALUES: Required<
  Omit<
    PlayerEmbedParams,
    | "analyticsContext"
    | "id"
    | "preload"
    | "src"
    | "srcDuration"
    | "srcTitle"
    | keyof DeprecatedPlayerEmbedParams
  >
> = Object.freeze({
  analytics: true,
  aspectRatio: "16:9",
  autoControls: false,
  autoFocus: true,
  autoMute: false,
  autoplay: false,
  autoPlayNextEpisode: false,
  backButton: false,
  backgroundVideo: false,
  chatButton: false,
  chromecast: false,
  controls: true,
  description: true,
  language: LANGUAGE_CODE.FIN,
  logo: true,
  loop: false,
  optimizeStartLevel: false,
  pictureInPicture: true,
  playbackHistory: false,
  playbackRate: 1,
  playButton: true,
  playerUi: true,
  playFullScreen: false,
  previewImage: true,
  recommendations: true,
  replayButton: false,
  seek: 0,
  sharing: true,
  smallPlayButton: false,
  spinner: true,
  subtitles: true,
  subtitlesBackground: Enums.SUBTITLES_BACKGROUND.NONE,
  subtitlesDefault: Enums.SUBTITLES_DEFAULT.translation,
  theme: Enums.THEME.LIGHT,
  timeLeft: false,
  title: true,
  unmuteButton: false,
  yleAudioPlayer: false,
  ylePlayer: true
});

export const YLE_PLAYER_SEEK_PREVIEW_WIDTH = 164;

export const DVR_WINDOW_OFFSET_IN_SECONDS = 30;

export const LIVE_BUFFER_IN_SECONDS = 18; // 3 segments

export const LIVE_EDGE_THRESHOLD_IN_SECONDS = 10;

export const SUBTITLES_FONT_SIZE_MAX = 52;
export const SUBTITLES_FONT_SIZE_MIN = 14;
export const SUBTITLES_FONT_SIZE_SQUAREVIDEO_MULTIPLIER = 1.125;
export const SUBTITLES_FONT_SIZE_WIDTH_BASED_MULTIPLIER = 0.03015;

export const SUBTITLES_OFF = {
  kind: "off",
  label: "",
  language: ""
};

export const HLS_MIME_TYPE = "application/vnd.apple.mpegurl";

export const SUBTITLES_BACKGROUND_COLOR = {
  [Enums.SUBTITLES_BACKGROUND.BLACK]: "rgba(0, 0, 0, 0.7)",
  [Enums.SUBTITLES_BACKGROUND.NONE]: "none",
  [Enums.SUBTITLES_BACKGROUND.WHITE]: "rgba(255, 255, 255, 0.75)"
};
export const END_OFFSET = 35;

export const CONTROL_VISIBILITY_TIMEOUTS = {
  KEYBOARD: 1000,
  MOVE_HOVER: 3000,
  OUT_HOVER: 1000
};

export const ONGOING_STATES = [
  State.ONGOING_ONDEMAND,
  State.ONGOING_EVENT,
  State.ONGOING_CHANNEL,
  State.SRC_AUDIO
];
