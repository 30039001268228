export const enum PLAYER_TYPE {
  CHANNEL = "Channel",
  ONDEMAND = "OnDemand",
  WEBCAST = "Webcast"
}

export enum State {
  ONGOING_ONDEMAND = "ongoing_ondemand",
  ONGOING_EVENT = "ongoing_event",
  ONGOING_CHANNEL = "ongoing_channel",
  PENDING_ONDEMAND = "pending_ondemand",
  PENDING_EVENT = "pending_event",
  SRC_AUDIO = "src_audio",
  GONE = "gone",
  BLOCKED = "blocked",
  ERROR = "error",
  NOT_ALLOWED = "not_allowed",
  NOT_ALLOWED_CHANNEL = "not_allowed_channel",
  IDENTIFICATION = "identification"
}

export const enum CONTENT_TYPE {
  VIDEO_OBJECT = "VideoObject",
  AUDIO_OBJECT = "AudioObject"
}

export const enum THEME {
  AUTO = "auto",
  DARK = "dark",
  LIGHT = "light"
}

export const enum SUBTITLES_BACKGROUND {
  BLACK = "black",
  WHITE = "white",
  NONE = "none"
}

export const enum SUBTITLES_DEFAULT {
  off = "off",
  hardOfHearing = "hardOfHearing",
  translation = "translation"
}

export const enum UI_SIZE {
  DEFAULT = "default",
  LARGE = "large",
  SMALL = "small",
  XSMALL = "xsmall"
}

export const enum SERIES_TYPES {
  TV_SERIES = "TVSeries",
  RADIO_SERIES = "RadioSeries"
}

export const enum PROGRAM_TYPES {
  TV_PROGRAM = "TVProgram",
  RADIO_PROGRAM = "RadioProgram"
}

export const enum MEDIA_TYPES {
  TV = "TV",
  RADIO = "Radio"
}

// https://github.com/kaltura/playkit-js/blob/master/src/error/severity.js
export const enum ErrorLevel {
  Recoverable = 1,
  Critical = 2
}

export enum PlayerUiType {
  off = "off", // All ui elements are hidden
  backgroundVideo = "backgroundVideo", // All ui elements, except small play button, are hidden
  hiddenControls = "hiddenControls", // Hide all controls, but show subtitles and other ui elements and click events etc.
  default = "default" // All ui elements are visible
}
